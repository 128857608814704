<template>
	<div id="dormitory" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>德育</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">住宿管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				
				<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="content u-f">
				<div class="divide">
					<div class="content1">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据，
								<span class="btn" @click="selectAll">选择全部</span>
							</div>
							<div class="u-f-item">
								<el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;" @change="sousuoList">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" @click="VisibleTap(1)">添加学生</el-button>
								<el-button size="small" @click="updateTap">批量更新</el-button>
								<el-button size="small" @click="PiLuru">批量录入</el-button>
								<el-button size="small" @click="exports">导出</el-button>
							</div>
						</div>
						<el-table
							:data="list"
							height="80%"
							ref="multipleTable"
							tooltip-effect="dark"
							@selection-change="handleSelectionChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
							key="2"
						>
							<el-table-column type="selection" width="55"></el-table-column>
							<el-table-column label="序号" type="index" width="150" align="center"></el-table-column>
							<el-table-column prop="accommodation" label="住宿情况" align="center">
								<template slot-scope="scope">
									<div>
										<el-select size="small" v-model="scope.row.accommodation" placeholder="请选择住宿情况">
											<el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
										</el-select>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="realname" label="姓名" align="center"></el-table-column>
							<el-table-column prop="sex_text" label="性别" align="center"></el-table-column>
							<el-table-column prop="student_num" label="学籍号" align="center"></el-table-column>
							<el-table-column prop="remark" label="备注" align="center">
								<template slot-scope="scope">
									<div><el-input placeholder="请输入备注" v-model="scope.row.remark" size="small"></el-input></div>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
							<div class="u-f-item">
								<el-button type="danger" plain size="mini" @click="delStu">删除</el-button>
								<el-button type="primary" plain size="mini" @click="save">保存</el-button>
							</div>
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</index>
		<el-dialog :title="VisibleTitle" :visible.sync="addVisible" width="700px" :before-close="handleClose">
			<div class="header u-f-item u-f-jsb">
				<div class="u-f-item">
					<span>共{{ s_total }}人</span>
					<el-select size="small" v-model="year1" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYears">
						<el-option v-for="item in yearList1" :key="item.value" :label="item.name" :value="item.value"></el-option>
					</el-select>
				</div>
				<div>
					<el-input placeholder="请输入姓名搜索" @change="sousuoname" v-model="realname" size="small" style="margin-right: 10px;">
						<i slot="prefix" class="el-input__icon el-icon-search"></i>
					</el-input>
				</div>
			</div>
			<div class="con-box">
				<div
					class="u-f-item"
					v-for="(item, index) in studentList"
					:key="index"
					:class="[item.disabled ? 'none' : 'can', item.selected ? 'selected' : '']"
					@click="choose(item.id)"
				>
					<div class="u-f1">{{ item.realname }}</div>
					<div class="u-f2">{{ item.sex_text }}</div>
				</div>
			</div>
			<div class="page">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange1"
					:current-page.sync="s_page"
					:page-size="limit"
					layout="total, prev, pager, next"
					:total="s_total"
				></el-pagination>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="submit">确 定</el-button>
			</span>
		</el-dialog>
		
		<!-- 批量更新 -->
		<el-dialog title="批量更新" :visible.sync="updateVisible" width="500px" :before-close="handleClose">
			<div class="update_box">
				<div class="update_item">
					<div>请按照模板格式进行录入</div>
					<div class="u-f-item" style="margin-top: 5px;">
						<!-- <img src="../../../dist/static/image/excel.png" style="width: 18px;margin-right: 3px;" /> -->
						<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
						<a :href="fileUrl">下载模板</a>
					</div>
				</div>
				<div class="u-f" style="margin-top: 20px;">
					<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
					<div>
						<el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
						<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
							<div class="u-f-item">
								<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
							</div>
							<i class="el-icon-circle-check" style="color: #67C23A;"></i>
							<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="gengxin">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 批量录入-->
		<el-dialog title="批量录入" :visible.sync="enteringVisible" width="500px" :before-close="handleClose">
			<div class="update_box">
				<div class="update_item" style="max-height: 130px;overflow-y: auto;">
					<div>请按照模板格式进行录入</div>
					<div class="u-f-item" style="padding-top: 10px;cursor: pointer;" v-for="(item,index) in yearList1" :key="index" @click="yearTap(item.value)">
						<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>
						<a>{{item.name}}</a>
					</div>
				</div>
				<div class="u-f" style="margin-top: 20px;">
					<div style="color: #B7BDC0;margin-right: 10px;margin-top: 5px;">数据录入</div>
					<div>
						<el-button size="mini" style="position: relative;"><input ref="filElem" type="file" class="upload-file" @change="getFile">点击上传</el-button>
						<div class="u-f-item u-f-jsb file_box" v-if="file.name" style="margin-top: 10px;width: 300px;">
							<div class="u-f-item">
								<i class="iconfont icon-excel" style="color: red;margin-right: 3px;"></i>{{file.name}}
							</div>
							<i class="el-icon-circle-check" style="color: #67C23A;"></i>
							<i class="el-icon-circle-close" style="color: #67C23A;" @click="delFile"></i>
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click="daoru">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	props: {},
	data() {
		return {
			type: '',
			loading: false,
			condition: 0,
			conditionList: [],
			year: '',
			yearList: [],
			semester: 1,
			semesterList: [],
			grade_id: 1,
			gradeList: [],
			// name: '',
			yearList1: [],
			year1: '',
			id: '',
			info: {},
			page: 1,
			limit: 10,
			total: 0,
			list: [],
			VisibleTitle: '',
			addVisible: false,
			student_ids: [],
			s_page: 1,
			s_total: 0,
			studentList: [],
			sousuo: '',
			classList: [{ id: 0, name: '请选择' },{ id: 1, name: '住宿' },{ id: 2, name: '不住宿' },{ id: 3, name: '午托' }],
			ids: [],
			realname: '',
			updateVisible: false,
			file: '',
			fileUrl:'',
			enteringVisible:false
		};
	},
	created() {},
	mounted() {
		this.getYearList();
	},
	methods: {
		twacherTap(index) {
			this.tabIndex = index;
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
					if (res.data.data.length) {
						if (this.yearList.filter(item => item.is_default == 2).length > 0) {
							this.year = this.yearList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.year = this.yearList[0].id;
						}
					} else {
						this.year = '';
					}
					this.getSemesterList();
				}
			});
		},
		changeYear(e) {
			this.year = e;
			this.page = 1;
			this.list = [];
			this.getSemesterList();
		},
		// 学期列表
		getSemesterList() {
			this.$api.setting.getXueQiList({ year_id: this.year }).then(res => {
				if (res.data.code == 1) {
					this.semesterList = res.data.data;
					if (res.data.data.length) {
						if (this.semesterList.filter(item => item.is_default == 2).length > 0) {
							this.semester = this.semesterList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.semester = this.semesterList[0].id;
						}
					} else {
						this.semester = '';
					}
					this.getGradeList();
				}
			});
		},
		changeSemester(e) {
			this.semester = e;
			this.page = 1;
			this.list = [];
			this.getList();
		},
		// 搜索
		sousuoList() {
			this.page = 1;
			this.list = [];
			this.getList();
		},
		// 年级列表
		getGradeList() {
			this.$api.setting.getGradeList({ filter: JSON.stringify({ year_id: this.year }) }).then(res => {
				if (res.data.code == 1) {
					this.gradeList = res.data.data.rows;
					if (this.gradeList.length) {
						this.grade_id = this.gradeList[0].id;
					} else {
						this.grade_id = '';
					}
					this.getList();
					this.getYearList1();
				}
			});
		},
		// 切换年级
		changeGrade(e) {
			this.grade_id = e;
			this.page = 1;
			this.list = [];
			this.getList();
		},

		// 关闭弹窗
		handleClose(done) {
			this.studentList = [];
			done();
		},
		handleSelectionChange(val) {
			let arr = [];
			for (let i in val) {
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids);
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		handleCurrentChange1(val) {
			this.s_page = val;
			this.getStudentList();
		},
		filterTag(value, row) {
			return row.tag === value;
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		selectAll() {
			let self = this;
			this.list.forEach(row => {
				self.$refs.multipleTable.toggleRowSelection(row, true);
			});
		},
		// 弹窗
		VisibleTap(type, item) {
			this.type = type;
			if (type == 1) {
				this.VisibleTitle = '添加学生';
				this.getStudentList();
			} else if (type == 2) {
			}
			this.addVisible = true;
		},
		// 关闭弹窗
		closeVisible() {
			this.addVisible = false;
			this.updateVisible = false;
			this.enteringVisible = false;
		},
		changeYears(e) {
			this.year1 = e;
			this.s_page = 1;
			this.studentList = [];
			this.getStudentList();
		},
		sousuoname() {
			this.s_page = 1;
			this.studentList = [];
			this.getStudentList();
		},
		getList() {
			let data = {
				semester_id: this.semester, // 	2 	string 	是 	学年ID
				page: this.page, // 	1 	string 	是 	分页
				limit: this.limit
			};
			if (this.sousuo) {
				data.keyword = this.sousuo;
			}
			this.$api.student.dormitory(data).then(res => {
				if (res.data.code == 1) {
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 学年列表
		getYearList1() {
			this.$api.student.entYear().then(res => {
				if (res.data.code == 1) {
					this.yearList1 = res.data.data;
					this.year1 = this.yearList[0].value;
					this.getStudentList();
				}
			});
		},
		// 获取可添加的学生列表
		getStudentList() {
			let data = {
				semester_id: this.semester, // 	2 	string 	是 	学年ID
				ent_year: this.year1,
				page: this.s_page,
				limit: this.limit
			};
			if (this.realname) {
				data.realname = this.realname;
			}
			this.$api.student.dormitoryStudentList(data).then(res => {
				if (res.data.code == 1) {
					let list = res.data.data.rows;
					this.s_total = res.data.data.total;
					this.studentList = list;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 选中学生
		choose(id) {
			this.studentList = this.studentList.map(item => {
				if (item.id == id && !item.disabled) {
					item.selected = !item.selected;
				}
				return item;
			});
		},
		// 添加、编辑分班
		submit() {
			if (this.type == 1) {
				this.addClass();
			} else if (this.type == 2) {
			}
		},
		// 添加分班
		addClass() {
			let list = this.studentList.filter(item => item.selected == true);
			let ids = list.map(item => {
				return item.id;
			});
			this.$api.student
				.Adddormitory({
					semester_id: this.semester, // 	2 	string 	是 	学年ID
					student_ids: ids
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.getList();
						this.addVisible = false;
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 导出
		exports() {
			this.$confirm('确定要导出？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				let data = {
					semester_id: this.semester // 	2 	string 	是 	学年ID
				};
				if (this.sousuo) {
					data.keyword = this.sousuo;
				}
				this.$api.student.exportdormitory(data).then(res => {
					if (res.data.code == 1) {
						this.$message.success('导出成功');
						location.href = res.data.data.url;
					} else {
						this.$message.error(res.data.msg);
					}
				});
			});
		},
		// 删除
		delStu() {
			let _this = this;
			if (this.ids.length) {
				this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(() => {
					this.$api.student
						.deldormitory({
							semester_id: this.semester, // 	2 	string 	是 	学年ID
							ids: this.ids
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.getList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
			} else {
				this.$message.error('请选择要删除的学生');
			}
		},
		// 全局保存
		save() {
			let list = [];
			for (let i = 0; i < this.list.length; i++) {
				let obj = {
					id: this.list[i].id,
					accommodation: this.list[i].accommodation,
					remark: this.list[i].remark
				};
				list.push(obj);
			}
			this.$api.student
				.editdormitory({
					semester_id: this.semester, // 	2 	string 	是 	学年ID
					student: list
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('保存成功');
						this.getList();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		getupdateImportTemp(){
			this.$api.student.updateImportTempdormitory({
				semester_id: this.semester
			}).then(res=>{
				if (res.data.code == 1) {
					this.fileUrl = res.data.data.url;
				} else {
					this.$message.error(res.data.msg);
				}
			})
		},
		updateTap(){
			this.getupdateImportTemp()
			this.updateVisible = true;
		},
		getFile(e){
			if(this.$refs.filElem.files.length){
				this.file = this.$refs.filElem.files[0]
			}
		},
		delFile(){
			this.file = '';
			this.$refs.filElem.value = '';
		},
		gengxin(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			formData.append("semester_id", this.semester);
			this.$api.student.updateImportdormitory(formData).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功');
					this.getList()
					this.file = '';
					this.updateVisible = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		// 下载模板
		yearTap(val){
			this.$api.student.importTempdormitory({
				semester_id: this.semester, 
				ent_year:val
			}).then(res=>{
				if(res.data.code==1){
					location.href = res.data.data.url;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		PiLuru(){
			this.file = '';
			this.enteringVisible = true;
		},
		// 导入
		daoru(){
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append("file", file);
			formData.append("semester_id", this.semester);
			this.$api.student.importdormitory(formData).then(res=>{
				if(res.data.code==1){
					this.$message.success('导入成功');
					this.getList()
					this.file = '';
					this.enteringVisible = false;
				}else{
					this.$message.error(res.data.msg);
				}
			})
		}
	}
};
</script>

<style lang="scss" scope>
#dormitory {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		background-color: #ffffff;
		height: 78vh;
		margin-top: 20px;
		padding: 0 30px;
		.building-list {
			padding: 25px 25px 25px 0;
			width: 20%;
			border-right: 1px solid #eee;
			.item {
				padding: 0 15px;
				height: 40px;
				line-height: 40px;
				margin-bottom: 15px;
				background-color: #f8f8f8;
			}
			.item:hover {
				color: #007aff;
				background: #e4f1ff;
				cursor: pointer;
			}
			.active {
				color: #007aff;
				background: #e4f1ff;
			}
		}
		.divide {
			width: 100%;
			padding: 30px 30px 0 30px;
			.content1 {
				background-color: #ffffff;
				overflow-y: auto;
				height: 100%;
				.title {
					padding: 0 0 15px 0;
					.btn {
						background-color: #f5f5f5;
						padding: 5px;
					}
					.btn:hover {
						color: #4998ff;
						background: #e4f1ff;
						cursor: pointer;
					}
				}
				.el-table th > .cell,
				.el-table .cell {
					overflow: hidden; /*超出部分隐藏*/
					white-space: nowrap; /*不换行*/
					text-overflow: ellipsis; /*超出部分文字以...显示*/
				}
				.el-table thead {
					background: #fafafa !important;
				}
				.el-table th {
					padding: 15px 5px !important;
					background: #fafafa !important;
				}
				.el-table::before {
					left: 0;
					bottom: 0;
					width: 100%;
					height: 0;
				}
				.el-table .btn {
					padding: 5px;
					text-align: center;
				}
			}
			
		}
	}
	.el-dialog__header {
		background-color: #f8f8f8;
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		.con-box {
			border-top: 1px solid #eee;
			margin-top: 15px;
			max-height: 400px;
			overflow-y: auto;
			> div {
				margin-top: 10px;
				background-color: #f6f8fb;
				padding: 15px;
				> div:first-child {
					// margin-right: 30%;
				}
			}
			> div.none {
				color: #d8dddf;
			}
			> div.can:hover {
				color: #4998ff;
				background: #e4f1ff;
				cursor: pointer;
			}
			> div.selected,
			> div.selected.can:hover {
				background-color: #4998ff;
				color: #fff;
			}
		}
		.page {
			margin-top: 10px;
			text-align: right;
		}
		.update_box {
			padding: 40px 20px;
			.update_item {
				background-color: #f6f8fb;
				padding: 20px;
				a {
					color: #007aff;
					text-decoration: none;
				}
			}
			.upload-file{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.file_box{
				padding: 5px;
				.el-icon-circle-check{
					display: block;
				}
				.el-icon-circle-close{
					display: none;
				}
			}
			.file_box:hover{
				color: #007AFF;
				background-color: #F6F8FB;
				.el-icon-circle-check{
					display: none;
				}
				.el-icon-circle-close{
					display: block;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
